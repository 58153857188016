import type { ZudokuConfig } from "zudoku";
import { sidebar } from "./sidebar";
import { GettingStartedCallout } from "./src/GettingStartedCallout";
import { LandingPage } from "./src/LandingPage";
import { PricingPage } from "./src/pricing-page/PricingPage";
import { SubscriptionsPage } from "./src/subscriptions-page/SubscriptionsPage";
import { CheckoutPage } from "./src/checkout-page/CheckoutPage";
import { ClientOnly } from "zudoku/components";

import test from "../config/backoffice.oas.json";
import { ChangeSubscriptionPage } from "./src/subscriptions-page/change-subscription-page/ChangeSubscriptionPage";

export const ZUPLO_ENDPOINT = "https://accuweather-poc-main-2d601ae.zuplo.app"; // "http://localhost:9009"

const config: ZudokuConfig = {
  page: {
    pageTitle: "",
    logo: {
      src: {
        light: "AccuWeatherLogoLight.svg",
        dark: "AccuWeatherLogoDark.svg",
      },
      width: "200px",
    },
  },
  metadata: {
    favicon:
      "https://developer.accuweather.com/sites/all/themes/accuweather/favicon.ico",
    title: "%s | AccuWeather Developer",
  },
  theme: {
    light: {
      primary: "22.1 89.7% 54.3%",
      secondary: "0 0 5%",
      secondaryForeground: "360 100% 100%",
      destructive: "0 64% 26%",
    },
    dark: {
      background: "0 0% 6.67%",
      primary: "22.1 89.7% 54.3%",
      primaryForeground: "360 100% 100%",
      mutedForeground: "215 9.15% 66.66%",
    },
  },
  customPages: [
    { path: "/home", element: <LandingPage /> },
    {
      path: "/pricing",
      element: (
        <ClientOnly>
          <PricingPage />
        </ClientOnly>
      ),
    },
    {
      path: "/checkout",
      render: CheckoutPage,
      prose: false,
    },
    { path: "/subscriptions", element: <SubscriptionsPage /> },
    {
      path: "/subscriptions/:subscription",
      render: ChangeSubscriptionPage,
    },
  ],
  authentication: {
    type: "auth0",
    domain: "dev-zw2j4kmvkhgbyymd.us.auth0.com",
    clientId: "skwjqoHBYApqgiF5vVJP0sLG2MDVTqzm",
    audience: "https://accuweather-poc.pages.dev/",
    redirectToAfterSignIn: "/subscriptions",
    redirectToAfterSignUp: "/subscriptions",
  },
  UNSAFE_slotlets: {
    "api-keys-list-page": <GettingStartedCallout />,
  },
  topNavigation: [
    { label: "Home", id: "home" },
    { label: "Documentation", id: "documentation" },
    { label: "Core Weather API", id: "core-weather" },
    { label: "Minute Cast API", id: "minute-cast" },
    { label: "Pricing", id: "pricing" },
    { label: "Subscriptions & Keys", id: "subscriptions", display: "auth" },
  ],
  sidebar: { documentation: sidebar },
  redirects: [
    { from: "/", to: "/home" },
    { from: "/documentation", to: "/documentation/getting-started" },
  ],
  apiKeys: {
    enabled: true,
    endpoint: ZUPLO_ENDPOINT,
  },
  apis: [
    {
      type: "url",
      input: ZUPLO_ENDPOINT + "/core-weather.json",
      navigationId: "core-weather",
    },
    {
      type: "raw",
      input: JSON.stringify(test),
      navigationId: "backoffice",
    },
    {
      type: "url",
      input: ZUPLO_ENDPOINT + "/minute-cast.json",
      navigationId: "minute-cast",
    },
  ],
  docs: {
    files: "/pages/**/*.mdx",
  },
};

export default config;
