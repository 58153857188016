import { useState } from "react";
import { Label } from "zudoku/ui/Label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "zudoku/ui/Select";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "zudoku/ui/Card";
import { Input } from "zudoku/ui/Input";
import { CardElement } from "@recurly/react-recurly";
import countries from "./countries.json";

export const CreditCard = () => {
  const [country, setCountry] = useState("");

  return (
    <Card className="w-full max-w-md">
      <CardHeader className="border-b">
        <CardTitle>Billing Information</CardTitle>
        <CardDescription>
          Your billing address and payment information
        </CardDescription>
      </CardHeader>
      <CardContent className="pt-4 space-y-1">
        <div className="space-y-2">
          <Label htmlFor="cardNumber">Credit Card</Label>
          <div className="[&_.recurly-element]:rounded border-border">
            <CardElement />
          </div>
        </div>
      </CardContent>
      <CardContent className="border-t pt-4 flex flex-col gap-2">
        <div className="space-y-1">
          <Label htmlFor="name">First Name</Label>
          <Input
            id="firstName"
            data-recurly="first_name"
            placeholder="John"
            name="firstName"
            required
          />
        </div>
        <div className="space-y-1">
          <Label htmlFor="name">Last Name</Label>
          <Input
            id="lastName"
            data-recurly="last_name"
            placeholder="Doe"
            name="lastName"
            required
          />
        </div>
        <div className="space-y-1">
          <Label htmlFor="name">Address</Label>
          <Input
            id="address"
            data-recurly="address1"
            placeholder="1234 Elm St"
            required
          />
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="space-y-1">
            <Label htmlFor="name">City</Label>
            <Input id="city" placeholder="Plano" data-recurly="city" required />
          </div>
          <div className="space-y-1">
            <Label htmlFor="name">ZIP</Label>
            <Input
              id="name"
              data-recurly="postal_code"
              placeholder="12345"
              required
            />
          </div>
          <div className="space-y-1 col-span-2">
            <Label htmlFor="name">Country</Label>
            <input type="hidden" data-recurly="country" value={country} />
            <Select
              defaultValue="US"
              onValueChange={(value) => setCountry(value)}
            >
              <SelectTrigger className="w-full">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {countries.map((country) => (
                  <SelectItem value={country["alpha-2"]}>
                    {country.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
