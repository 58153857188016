import type { ComponentType, ReactNode } from "react";
import { CoreWeatherIcon } from "./CoreWeatherIcon";
import { MinuteCastWeatherIcon } from "./MinuteCastWeatherIcon";
import { WindIcon } from "lucide-react";

export type Plan = {
  id: string;
  label: ReactNode;
  link?: {
    to: string;
    text: string;
  };
  highlight?: boolean;
  note: ReactNode;
  price: string;
  pricePer?: string;
  features?: Record<string, any>;
};

export type Product = {
  icon: ComponentType<{ className: string }>;
  plans: Plan[];
  label: string;
  description: ReactNode;
  features: string[];
};

export const MinuteCastPlans: Plan[] = [
  {
    id: "minute-cast-free",
    label: "Free",
    link: {
      to: "/subscriptions",
      text: "Start for Free",
    },
    note: (
      <>
        25 calls per day.
        <br />1 key per developer
      </>
    ),
    price: "$0",
  },
  {
    id: "minute-cast-lite",
    label: "Lite",
    note: "$0.18 CPM over 10,000 calls per month",
    price: "$25",
    pricePer: "mo",
  },
  {
    id: "minute-cast-full",
    label: "Full",
    note: "$0.12 CPM over 675,000 calls per month",
    price: "$100",
    pricePer: "mo",
  },
];

export const AirQualityPlans: Plan[] = [
  {
    id: "air-quality-free",
    label: "Free",
    link: {
      to: "/subscriptions",
      text: "Start for Free",
    },
    note: (
      <>
        25 calls per day.
        <br />1 key per developer
      </>
    ),
    price: "$0",
    features: {
      "Air Quality Index": "1 Day",
      Pollutants: "1 Day",
    },
  },
  {
    id: "air-quality-lite",
    label: "Lite",
    note: "$0.18 CPM over 10,000 calls per month",
    price: "$25",
    pricePer: "mo",
    features: {
      "Air Quality Index": "3 Days",
      Pollutants: "3 Days",
    },
  },
  {
    id: "air-quality-full",
    label: "Full",
    note: "$0.12 CPM over 675,000 calls per month",
    price: "$100",
    pricePer: "mo",
    features: {
      "Air Quality Index": "5 Days",
      Pollutants: "5 Days",
    },
  },
];

const CoreWeatherPlans: Plan[] = [
  {
    id: "core-weather-free",
    label: "Free",
    note: (
      <>
        50 calls per day.
        <br />
        Only 1 key per developer
      </>
    ),
    price: "$0",
    link: {
      to: "/subscriptions",
      text: "Start for Free",
    },
    features: {
      Locations: true,
      "Current Conditions": true,
      "24 Hours Historical Current Conditions": true,
      "Daily Forecast": "5 Days",
      "Hourly Forecast": "12 Hours",
      Indices: "5 Days",
      Alarms: "",
      Translations: false,
      Tropical: false,
      Alerts: false,
      Imagery: false,
    },
  },
  {
    id: "core-weather-standard",
    label: "Standard",
    note: "0.12 CPM over 225,000 calls per month",
    highlight: true,
    price: "$25",
    pricePer: "mo",
    features: {
      Locations: true,
      "Current Conditions": true,
      "24 Hours Historical Current Conditions": true,
      "Daily Forecast": "5 Days",
      "Hourly Forecast": "12 Hours",
      Indices: "5 Days",
      Alarms: "5 Days",
      Translations: true,
      Tropical: false,
      Alerts: false,
      Imagery: false,
    },
  },
  {
    id: "core-weather-prime",
    label: "Prime",
    note: "0.15 CPM over 1,800,000 calls per month",
    highlight: true,
    price: "$250",
    pricePer: "mo",
    features: {
      Locations: true,
      "Current Conditions": true,
      "24 Hours Historical Current Conditions": true,
      "Daily Forecast": "5 Days",
      "Hourly Forecast": "72 Hours",
      Indices: "10 Days",
      Alarms: "10 Days",
      Translations: true,
      Tropical: true,
      Alerts: true,
      Imagery: true,
    },
  },
  {
    id: "core-weather-elite",
    label: "Elite",
    note: "0.22 CPM over 2,400,000 calls per month",
    price: "$500",
    pricePer: "mo",
    features: {
      Locations: true,
      "Current Conditions": true,
      "24 Hours Historical Current Conditions": true,
      "Daily Forecast": "15 Days",
      "Hourly Forecast": "120 Hours",
      Indices: "15 Days",
      Alarms: "15 Days",
      Translations: true,
      Tropical: true,
      Alerts: true,
      Imagery: true,
    },
  },
];

export const Products: Product[] = [
  {
    icon: CoreWeatherIcon,
    plans: CoreWeatherPlans,
    label: "Core Weather",
    description:
      "Discover the power of AccuWeather Developer APIs, offering precise and real-time weather data for your applications. Our APIs provide reliable and accurate weather insights, perfect for enhancing mobile apps, websites, and enterprise solutions.",
    features: [
      "Locations",
      "Current Conditions",
      "24 Hours Historical Current Conditions",
      "Daily Forecast",
      "Hourly Forecast",
      "Indices",
      "Alarms",
      "Translations",
      "Tropical",
      "Alerts",
      "Imagery",
    ],
  },
  {
    icon: MinuteCastWeatherIcon,
    plans: MinuteCastPlans,
    description:
      "Experience minute-by-minute weather forecasts with AccuWeather MinuteCast. This unique feature provides precise precipitation type and intensity, start and end times for the next two hours, all localized to your exact street address or GPS location.",
    label: "MinuteCast Weather",
    features: [],
  },
  {
    icon: WindIcon,
    plans: AirQualityPlans,
    description:
      "Monitor air quality in real-time with AccuWeather Air Quality API. Our API provides air quality data for over 100 countries, including the US, UK, Canada, and India.",
    label: "Air Quality",
    features: ["Air Quality Index", "Pollutants"],
  },
];
