import { useZudoku } from "zudoku/components";
import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQueryClient,
  useSuspenseQuery,
  UseSuspenseQueryOptions,
} from "@tanstack/react-query";
import { ZUPLO_ENDPOINT } from "../zudoku.config";

export const useSignedQuery = <
  TData = any,
  TError = unknown,
  TQueryKey extends QueryKey = QueryKey,
>(
  path: string,
  options: Omit<
    UseSuspenseQueryOptions<TData, TError, TData, TQueryKey>,
    "queryKey" | "queryFn"
  > = {},
) => {
  const zudoku = useZudoku();
  return useSuspenseQuery<TData, TError, TData, TQueryKey>({
    queryKey: [path] as unknown as TQueryKey,
    queryFn: async () => {
      const signedRequest = await zudoku.signRequest(
        new Request(ZUPLO_ENDPOINT + path),
      );
      const response = await fetch(signedRequest);
      return response.json();
    },
    ...options,
  });
};

type MutationArgs = {
  headers?: Record<string, string>;
  params?: Record<string, string>;
  data?: any;
};

export const useSignedMutation = <
  TData = any,
  TError = unknown,
  TVariables = MutationArgs,
  TContext = unknown,
>(
  path: string,
  { method = "POST" }: { method: string } = {
    method: "POST",
  },
  options: UseMutationOptions<TData, TError, TVariables, TContext> = {},
) => {
  const zudoku = useZudoku();
  const queryClient = useQueryClient();

  return useMutation<TData, TError, TVariables, TContext>({
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries();
      options.onSuccess?.(...args);
    },
    mutationFn: async (args?: {
      headers?: Record<string, string>;
      params?: Record<string, string>;
      data?: any;
    }) => {
      const finalPath = Object.entries(args.params ?? {}).reduce(
        (acc, [key, value]) => acc.replace(`{${key}}`, value),
        path,
      );

      return (
        await fetch(
          await zudoku.signRequest(
            new Request(ZUPLO_ENDPOINT + finalPath, {
              method,
              body: JSON.stringify(args.data),
              headers: args.headers,
            }),
          ),
        )
      ).json();
    },
  });
};
