import { Head } from "zudoku/components";
import { queryClient } from "../../queries/client";
import { cn } from "../../components/cn";
import { Button } from "zudoku/ui/Button";
import React from "react";
import { Product, Products } from "../../pricing-page/Products";
import { ExposedComponentProps } from "zudoku";
import { useSignedMutation, useSignedQuery } from "../../useQuery";
import {
  Subscription,
  SUBSCRIPTIONS_PATH,
} from "../../queries/subscriptionsQuery";
import { QueryClientProvider } from "@tanstack/react-query";
import { ProductFeatures } from "../ProductFeatures";
import { useShoppingCart } from "../../pricing-page/ShoppingCart";

const ProductCard = ({
  product,
  currentPlanId,
  subscriptionId,
  navigate,
}: {
  navigate: (to: string) => void;
  product: Product;
  currentPlanId: string;
  subscriptionId: string;
}) => {
  const cart = useShoppingCart({ defaultItems: [] });

  const cancelSubscriptionMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscriptionId}/status`,
    { method: "PUT" },
    { onSuccess: () => navigate("/subscriptions") },
  );

  const cancelConfirmationMessage =
    "Are you sure you want to cancel your subscription?";

  return (
    <div className="flex flex-col gap-4" id={product.label}>
      <div
        className={cn(
          "grid grid-cols-[280px_1fr] border border-border rounded-lg shadow",
          product.plans.length === 4 && `grid-cols-5`,
          product.plans.length === 3 && `grid-cols-4`,
          product.plans.length === 2 && `grid-cols-3`,
          product.plans.length === 1 && `grid-cols-2`,
        )}
      >
        <div className="grid grid-rows-[1fr_min-content] grid-cols-subgrid col-span-full">
          <div>
            <div className="flex gap-2 text-lg p-4 font-semibold items-center">
              <product.icon className="not-prose h-10" />
              {product.label}
            </div>
          </div>
          {product.plans.map((plan) => (
            <div
              className={cn("border-l flex flex-col gap-1 p-4")}
              key={plan.id}
            >
              <div className="w-full flex justify-between">
                <div className="text-xl font-semibold">{plan.label}</div>
              </div>
              <span className="text-sm text-muted-foreground">{plan.note}</span>
              <span className="font-bold">
                {plan.price}
                {plan.pricePer && <span className="">/{plan.pricePer}</span>}
              </span>
              <Button
                variant={
                  plan.id === currentPlanId ? "destructive" : "secondary"
                }
                disabled={!!plan.link || cancelSubscriptionMutation.isPending}
                className={cn("w-full my-2")}
                onClick={() => {
                  if (plan.id === currentPlanId) {
                    // cancel subscription
                    if (!confirm(cancelConfirmationMessage)) {
                      return;
                    }
                    cancelSubscriptionMutation.mutate({
                      data: "canceled",
                    });
                  }
                  if (plan.id !== currentPlanId) {
                    // change plan
                    cart.clearCart();
                    console.log("cart", cart.length);
                    cart.addToCart(plan.id, plan.id);
                    navigate(`/checkout?from=${subscriptionId}`);
                  }
                }}
              >
                {plan.id === currentPlanId ? "Cancel" : "Upgrade"}
              </Button>
            </div>
          ))}
        </div>

        <ProductFeatures product={product} />
      </div>
    </div>
  );
};

const Page = ({ params, navigate }: ExposedComponentProps) => {
  const subscriptionsQuery = useSignedQuery<Subscription[]>(SUBSCRIPTIONS_PATH);
  if (!params.subscription) {
    throw new Error("Subscription not found");
  }

  const subscription = subscriptionsQuery.data.find(
    (subscription) => subscription.id === params.subscription,
  );

  if (!subscription) {
    console.log(subscriptionsQuery);
    throw new Error("Subscription not found");
  }

  const product = Products.find((product) =>
    product.plans.find((plan) => plan.id === subscription.planId),
  );

  if (!product) {
    throw new Error("Product not found");
  }

  return (
    <section className="flex flex-col py-12 gap-10 max-w-screen-xl mx-auto not-prose">
      <Head>
        <title>Pricing</title>
      </Head>
      <div className="flex flex-col items-center justify-between gap-6">
        <div className="flex justify-between w-full">
          <h2 className="font-semibold text-2xl">Update Subscription</h2>
          <Button
            variant="outline"
            className="min-w-36"
            onClick={() => navigate("/subscriptions")}
          >
            Back
          </Button>
        </div>
        <ProductCard
          product={product}
          currentPlanId={subscription.planId}
          subscriptionId={subscription.id}
          navigate={navigate}
        />
      </div>
    </section>
  );
};

export const ChangeSubscriptionPage = (props: ExposedComponentProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Page {...props} />
    </QueryClientProvider>
  );
};
