import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "zudoku/ui/Card";
import { LoaderIcon } from "lucide-react";
import { useCallback, useEffect } from "react";

export const SuccessCard = ({ onDone }: { onDone: () => void }) => {
  const onDoneCallback = useCallback(onDone, [onDone]);
  useEffect(() => {
    setTimeout(onDone, 5000);
  }, [onDoneCallback]);

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-center">Payment successful!</CardTitle>
        <CardDescription></CardDescription>
      </CardHeader>
      <CardContent className="flex gap-2 items-center justify-center text-muted-foreground text-sm font-semibold">
        <LoaderIcon size={16} className="animate-[spin_2s_linear_infinite]" />
        Redirecting to Subscriptions & Keys
      </CardContent>
    </Card>
  );
};
