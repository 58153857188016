import { Head, Link, useAuth } from "zudoku/components";
import { ArrowRightIcon, CheckIcon, ShoppingCartIcon } from "lucide-react";
import { cn } from "../components/cn";
import { Products } from "./Products";
import { useShoppingCart } from "./ShoppingCart";
import { Button } from "zudoku/ui/Button";
import { Checkbox } from "zudoku/ui/Checkbox";
import React from "react";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../queries/client";
import { useSignedQuery } from "../useQuery";
import {
  Subscription,
  SUBSCRIPTIONS_PATH,
} from "../queries/subscriptionsQuery";
import { ProductFeatures } from "../subscriptions-page/ProductFeatures";

const AuthenticatedPage = () => {
  const subscriptionsQuery = useSignedQuery<Subscription[]>(SUBSCRIPTIONS_PATH);

  const subscribedProductsList = subscriptionsQuery.data?.map(
    (subscription) => subscription.planId,
  );

  return (
    <Page
      isAuthenticated={true}
      subscribedProductsList={subscribedProductsList}
    />
  );
};

const AnonymousPage = () => {
  return <Page isAuthenticated={false} />;
};

const Page = ({
  isAuthenticated,
  subscribedProductsList = [],
}: {
  isAuthenticated: boolean;
  subscribedProductsList?: string[];
}) => {
  const shoppingCart = useShoppingCart<string>({ defaultItems: [] });

  return (
    <section className="flex flex-col items-center py-12 gap-4 max-w-screen-lg mx-auto">
      <Head>
        <title>Pricing</title>
      </Head>
      <div className="text-center">
        <h1 className="font-extrabold flex items-center text-[40px] justify-center gap-4">
          Our API Pricing
        </h1>
      </div>
      <div className="flex justify-between w-full items-center">
        <div className="flex px-1 gap-3 py-1 text-sm items-center font-medium">
          Our APIs:
          {Products.map((product, i) => (
            <a
              href={`#${product.label}`}
              className="rounded-md flex items-center gap-1 text-sm font-medium"
            >
              <product.icon className="w-4" />
              {product.label}
            </a>
          ))}
        </div>
        {isAuthenticated && (
          <Button
            disabled={shoppingCart.length === 0}
            className="min-w-52"
            asChild={shoppingCart.length > 0}
          >
            <Link to="/checkout" className="flex items-center">
              {shoppingCart.length === 0
                ? "No subscriptions"
                : `(${shoppingCart.length}) Checkout`}
              <ShoppingCartIcon size={16} className="ml-2" />
            </Link>
          </Button>
        )}
      </div>
      {Products.map((product) => (
        <div className="flex flex-col gap-4" id={product.label}>
          {/*<p className="text-muted-foreground max-w-3xl self-center text-center">*/}
          {/*  {product.description}*/}
          {/*</p>*/}
          <div
            className={cn(
              "grid grid-cols-[280px_1fr] border border-border rounded-lg shadow",
              product.plans.length === 4 && `grid-cols-5`,
              product.plans.length === 3 && `grid-cols-4`,
              product.plans.length === 2 && `grid-cols-3`,
              product.plans.length === 1 && `grid-cols-2`,
            )}
          >
            <div className="grid grid-rows-[1fr_min-content] grid-cols-subgrid col-span-full">
              <div>
                <div className="flex gap-2 text-lg p-4 font-semibold items-center">
                  <product.icon className="not-prose h-10" /> {product.label}
                </div>
              </div>
              {product.plans.map((plan, i) => (
                <div
                  className={cn(
                    "border-l flex flex-col gap-1 p-4",
                    !isAuthenticated &&
                      plan.highlight &&
                      "bg-amber-50 dark:bg-red-950/50",
                  )}
                  key={plan.id}
                >
                  <div className="w-full flex justify-between">
                    <div className="text-xl font-semibold">{plan.label}</div>

                    {isAuthenticated &&
                      !plan.link &&
                      !subscribedProductsList.includes(plan.id) && (
                        <Checkbox
                          checked={shoppingCart.hasItem(plan.id)}
                          onCheckedChange={() => {
                            shoppingCart.hasItem(plan.id)
                              ? shoppingCart.removeFromCart(plan.id)
                              : shoppingCart.addToCart(plan.id, plan.id);
                          }}
                        />
                      )}
                  </div>
                  <span className="text-sm text-muted-foreground">
                    {plan.note}
                  </span>
                  <span className="font-bold">
                    {plan.price}
                    {plan.pricePer && (
                      <span className="">/{plan.pricePer}</span>
                    )}
                  </span>
                  {isAuthenticated ? (
                    <Button
                      disabled={subscribedProductsList.includes(plan.id)}
                      variant={
                        shoppingCart.hasItem(plan.id)
                          ? "destructive"
                          : i > 0
                            ? "secondary"
                            : "default"
                      }
                      className={cn("w-full my-2")}
                      onClick={() => {
                        shoppingCart.hasItem(plan.id)
                          ? shoppingCart.removeFromCart(plan.id)
                          : shoppingCart.addToCart(plan.id, plan.id);
                      }}
                      asChild={!!plan.link}
                    >
                      {subscribedProductsList.includes(plan.id) ? (
                        "Already subscribed"
                      ) : plan.link ? (
                        <Link to={plan.link.to}>{plan.link.text}</Link>
                      ) : shoppingCart.hasItem(plan.id) ? (
                        "Remove"
                      ) : (
                        "Add to cart"
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant={i > 0 ? "secondary" : "default"}
                      className={"w-full my-2"}
                      asChild
                    >
                      <Link to="/signup">
                        Get started{" "}
                        <ArrowRightIcon
                          className="ml-2"
                          size={16}
                          strokeWidth={1.5}
                        />
                      </Link>
                    </Button>
                  )}
                </div>
              ))}
            </div>
            <ProductFeatures product={product} />
          </div>
        </div>
      ))}

      <div className="text-center">
        <h3>Need more?</h3>
        <p className="text-muted-foreground max-w-screen-md">
          To hear about additional content, climatology data, high volume CPM
          rates, or other business opportunities, please contact our sales team
          at sales@accuweather.com to learn about what else AccuWeather has to
          offer!
        </p>
        <Button variant="outline">Contact Sales</Button>
      </div>
      <p className="mt-8 text-sm text-muted-foreground">
        <span className="uppercase font-semibold">Branding requirements:</span>{" "}
        You are required to display proper attribution when using AccuWeather
        APIs. Please include the AccuWeather logo, linked to our website
        (http://www.accuweather.com) in clear view on your app or product.
        AccuWeather logo with linking must appear on all pages/screens where
        AccuWeather content appears, as stated in the Terms of Use.
      </p>
    </section>
  );
};

export const PricingPage = () => {
  const { isAuthenticated } = useAuth();
  return (
    <QueryClientProvider client={queryClient}>
      {isAuthenticated ? <AuthenticatedPage /> : <AnonymousPage />}
    </QueryClientProvider>
  );
};
