import { ClientOnly, Head } from "zudoku/components";
import { useShoppingCart } from "../pricing-page/ShoppingCart";
import { Products } from "../pricing-page/Products";
import { Card } from "zudoku/ui/Card";
import { ShoppingCartIcon, Trash2Icon } from "lucide-react";
import { QueryClientProvider } from "@tanstack/react-query";
import { useSignedMutation } from "../useQuery";
import { cn } from "../components/cn";
import { Button } from "zudoku/ui/Button";
import { useEffect, useState } from "react";
import { CreditCard } from "./CreditCard";
import { Elements, RecurlyProvider, useRecurly } from "@recurly/react-recurly";
import { SuccessCard } from "./SuccessCard";
import { queryClient } from "../queries/client";
import { ExposedComponentProps } from "zudoku";

const RECURLY_PUBLIC_KEY = "ewr1-hdecgYLYwzRUOz7BwgJwVF";

const useRecurlyLoader = () => {
  const [recurlyReady, setRecurlyReady] = useState(false);
  useEffect(() => {
    const configure = (retry = 0) => {
      setTimeout(() => {
        if (retry > 5) {
          return;
        }
        if ("recurly" in window) {
          window.recurly.configure(RECURLY_PUBLIC_KEY);
          setRecurlyReady(true);
          return;
        } else {
          configure(retry + 1);
        }
      }, 200);
    };

    configure();
  }, []);

  return {
    recurlyReady,
  };
};

const Page = ({
  onDone,
  upgradeFrom,
}: {
  onDone: () => void;
  upgradeFrom: string;
}) => {
  const shoppingCart = useShoppingCart<string>({ defaultItems: [] });
  const subscribeMutation = useSignedMutation("/v1/developer/subscriptions");
  const upgradeMutation = useSignedMutation(
    `/v1/developer/subscriptions/{subscriptionId}/status`,
    { method: "PUT" },
  );
  const recurly = useRecurly();

  return (
    <section className="flex flex-col py-12 gap-4 max-w-screen-lg mx-auto">
      <form
        onSubmit={async (e) => {
          e.preventDefault();

          const token = await new Promise<{ id: string; type: string }>(
            (res, rej) => {
              recurly.token(e.currentTarget, (err, token) => {
                if (err) {
                  rej(err);
                } else {
                  res(token);
                }
              });
            },
          );
          if (upgradeFrom) {
            if (shoppingCart.length > 0) {
              throw new Error("Cannot upgrade with multiple subscriptions");
            }
            upgradeMutation.mutate({
              data: shoppingCart.items.at(0).value,
              params: { subscriptionId: upgradeFrom },
              headers: {
                "x-recurly-token": token.id,
              },
            });
          } else {
            subscribeMutation.mutate({
              data: shoppingCart.items.map((item) => ({ planId: item.value })),
              headers: {
                "x-recurly-token": token.id,
              },
            });
          }
        }}
      >
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-1">
            <h1 className="font-extrabold text-start text-2xl m-0">Checkout</h1>
            <p className="text-muted-foreground m-0">
              Your selected subscription
            </p>
          </div>
          <div
            className={cn(
              "flex gap-2 justify-end",
              subscribeMutation.isSuccess && "hidden",
            )}
          >
            <Button
              type="submit"
              variant="outline"
              disabled={shoppingCart.length === 0}
              onClick={() => {
                if (confirm("Are you sure you want to clear your cart?")) {
                  shoppingCart.clearCart();
                }
              }}
            >
              Clear
            </Button>
            <Button
              type="submit"
              disabled={
                shoppingCart.length === 0 || subscribeMutation.isPending
              }
            >
              ({shoppingCart.length}) Checkout
              <ShoppingCartIcon className="ml-2" size={16} />
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-[3fr,2fr] w-full gap-8">
          <div>
            <Card className="grid grid-cols-1 w-full">
              {shoppingCart.length === 0 && (
                <div className="p-5 border-b text-sm">No products selected</div>
              )}
              {shoppingCart.items.map((item, i) => {
                const product = Products.find((product) =>
                  product.plans.find((plan) => plan.id === item.value),
                );
                const plan = Products.flatMap((product) => product.plans).find(
                  (plan) => plan.id === item.value,
                );

                return (
                  <div className="p-5 border-b flex gap-2.5 flex-col">
                    <div className="flex justify-between">
                      <div className="flex items-center gap-2">
                        <product.icon className="h-8 w-8" />
                        <div className="font-semibold text-xl">
                          {product.label}
                        </div>
                      </div>
                      <div className="text-lg font-semibold">{plan.label}</div>
                    </div>
                    <div className="grid grid-cols-[1fr,min-content]">
                      <div>
                        <div className="font-semibold">$250/mo</div>
                        <div className="text-sm text-muted-foreground">
                          $0.15 CPM over 1,800,000 calls per month
                        </div>
                      </div>
                      <div className="flex items-center">
                        <button
                          className="p-3 hover:bg-neutral-100 rounded"
                          onClick={() => {
                            if (
                              confirm("Are you sure you want to remove this?")
                            ) {
                              shoppingCart.removeFromCart(item.id);
                            }
                          }}
                        >
                          <Trash2Icon size={16} />
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
          <div className="">
            {subscribeMutation.isSuccess && <SuccessCard onDone={onDone} />}
            {!subscribeMutation.isSuccess && <CreditCard />}
          </div>
        </div>
      </form>
    </section>
  );
};

export const CheckoutPage = ({
  navigate,
  searchParams,
}: ExposedComponentProps) => {
  const { recurlyReady } = useRecurlyLoader();

  return (
    <>
      <Head>
        <title>Checkout</title>
        <script src="https://js.recurly.com/v4/recurly.js"></script>
        <link
          href="https://js.recurly.com/v4/recurly.css"
          rel="stylesheet"
          type="text/css"
        />
      </Head>
      {recurlyReady && (
        <ClientOnly>
          <QueryClientProvider client={queryClient}>
            <RecurlyProvider publicKey={RECURLY_PUBLIC_KEY}>
              <Elements>
                <Page
                  onDone={() => navigate("/subscriptions")}
                  upgradeFrom={searchParams.get("from")}
                />
              </Elements>
            </RecurlyProvider>
          </QueryClientProvider>
        </ClientOnly>
      )}
    </>
  );
};
