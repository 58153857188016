import { cn } from "../components/cn";
import {
  CheckIcon,
  CopyIcon,
  EyeIcon,
  EyeOffIcon,
  RotateCwIcon,
  Trash2Icon,
} from "lucide-react";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "zudoku/ui/Button";

export type Key = {
  id?: string;
  key: string;
  createdOn: string;
  expiresOn: string;
};

type ApiKeyService = {
  rollKey?: (id: string) => Promise<void>;
  deleteKey?: (id: string) => Promise<void>;
  deleteGroup?: (id: string) => Promise<void>;
  updateKeyDescription?: (apiKey: {
    id: string;
    description: string;
  }) => Promise<void>;
  getUsage?: (apiKeys: string[]) => Promise<void>;
  createKey?: (apiKey: {
    description: string;
    expiresOn?: string;
  }) => Promise<void>;
};

export const ApiKeyGroup = ({
  id,
  description,
  title,
  keys,
  service,
}: {
  id: string;
  title: string;
  description?: string;
  keys: Key[];
  service: ApiKeyService;
}) => {
  const queryClient = useQueryClient();

  const deleteKeyMutation = useMutation({
    mutationFn: (id: string) => {
      if (!service.deleteKey) {
        throw new Error("deleteKey not implemented");
      }

      return service.deleteKey(id);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: ["api-keys"] });
    },
  });

  const rollKeyMutation = useMutation({
    mutationFn: (id: string) => {
      if (!service.rollKey) {
        throw new Error("rollKey not implemented");
      }

      return service.rollKey(id);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["api-keys"] }),
  });

  const deleteGroup = useMutation({
    mutationFn: (id: string) => {
      if (!service.deleteGroup) {
        throw new Error("rollKey not implemented");
      }

      return service.deleteGroup(id);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["api-keys"] }),
  });

  return (
    <div>
      <div className="flex gap-2 items-center mb-1">
        <div className="font-semibold">{title}</div>
        {service.rollKey && (
          <Button
            title="Delete this app?"
            variant="ghost"
            onClick={() => {
              if (
                prompt(
                  `Type the "${title}" of the app to confirm you want to delete it`,
                ) !== title
              ) {
                return;
              }

              deleteGroup.mutate(id);
            }}
          >
            <Trash2Icon size={16} />
          </Button>
        )}
      </div>
      <ul className={cn("w-full flex flex-col gap-4")}>
        {[...keys]
          .sort((a, b) =>
            a.expiresOn && b.expiresOn
              ? new Date(a.expiresOn).getTime() -
                new Date(b.expiresOn).getTime()
              : a.expiresOn
                ? 1
                : -1,
          )
          .map((key, i) => (
            <li className="flex gap-0.5 flex-col" key={key.id}>
              {description}
              <div className="flex flex-row gap-2">
                <RevealApiKey apiKey={key.key} />
                {i === 0 && (
                  <Button
                    variant="outline"
                    size="icon"
                    onClick={() => {
                      if (!confirm("Do you want to roll this key?")) {
                        return;
                      }

                      rollKeyMutation.mutate(key.id);
                    }}
                    disabled={rollKeyMutation.isPending}
                  >
                    <RotateCwIcon size={16} />
                  </Button>
                )}
                {key.expiresOn &&
                  new Date(key.expiresOn) < new Date() &&
                  service.deleteKey && (
                    <Button
                      variant="outline"
                      size="icon"
                      onClick={() => {
                        if (!confirm("Do you want to delete this key?")) {
                          return;
                        }

                        deleteKeyMutation.mutate(key.id);
                      }}
                      disabled={deleteKeyMutation.isPending}
                    >
                      <Trash2Icon size={16} />
                    </Button>
                  )}
              </div>
              <div className="text-muted-foreground text-sm">
                {key.createdOn && (
                  <>Created on {new Date(key.createdOn).toLocaleDateString()}</>
                )}
                {key.createdOn && key.expiresOn && ", "}
                {key.expiresOn && (
                  <>Expires on {new Date(key.expiresOn).toLocaleDateString()}</>
                )}
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

const RevealApiKey = ({ apiKey }: { apiKey: string }) => {
  const [revealed, setRevealed] = useState(false);
  const [copied, setCopied] = useState(false);

  return (
    <div className="flex gap-2 items-center text-sm w-full">
      <div className="border rounded bg-gray-100 dark:bg-gray-950 p-1 font-mono truncate h-9 items-center flex px-2 w-full">
        {revealed ? apiKey : "•".repeat(apiKey.length)}
      </div>
      <Button
        variant="outline"
        onClick={() => setRevealed((prev) => !prev)}
        size="icon"
      >
        {revealed ? <EyeOffIcon size={16} /> : <EyeIcon size={16} />}
      </Button>
      <Button
        variant="outline"
        onClick={() => {
          void navigator.clipboard.writeText(apiKey).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
          });
        }}
        size="icon"
      >
        {copied ? <CheckIcon size={16} /> : <CopyIcon size={16} />}
      </Button>
    </div>
  );
};
