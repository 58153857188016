import { Head, Link } from "zudoku/components";
import { QueryClientProvider } from "@tanstack/react-query";
import { Progress } from "./Progress";
import { cn } from "../components/cn";
import { ApiKeyGroup } from "./ApiKeyGroup";
import { useSignedMutation, useSignedQuery } from "../useQuery";
import { Skeleton } from "zudoku/ui/Skeleton";
import { AddAppDialog } from "./AddAppDialog";
import {
  Subscription,
  SUBSCRIPTIONS_PATH,
} from "../queries/subscriptionsQuery";
import { queryClient } from "../queries/client";
import { Button } from "zudoku/ui/Button";
import { EditIcon } from "lucide-react";

const SubscriptionSkeleton = () => {
  return (
    <div className="self-start border rounded shadow w-full">
      <div className="bg-border/20 p-6 border-b flex justify-between items-center">
        <div id="minutecast" className="flex flex-col gap-1.5 w-72">
          <Skeleton className="h-4 w-full rounded-full" />
          <Skeleton className="h-4 w-8/12 rounded-full" />
        </div>

        <Skeleton className="h-10 w-32" />
      </div>
      <div className="border-b p-6 flex flex-col gap-1.5 ">
        <Skeleton className="h-4 w-28 rounded-full " />
        <Skeleton className="h-4 w-full rounded-full" />
      </div>
      <div className="border-b p-6 gap-4 grid-cols-2 grid">
        <div>
          <Skeleton className="h-4 w-24 rounded-full " />
        </div>
        <div className="flex justify-end">
          <Skeleton className="h-10 w-32 self-end" />
        </div>
        <div>
          <Skeleton className="h-4 w-80 rounded-full" />
        </div>
        <div className="flex gap-2 justify-end w-full">
          <Skeleton className="h-10 w-10 self-end" />
          <Skeleton className="h-10 w-10 self-end" />
        </div>
      </div>
    </div>
  );
};

const SubscriptionCard = ({ subscription }: { subscription: Subscription }) => {
  const rollApiKeyMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscription.id}/apps/{appId}/keys/`,
    { method: "POST" },
  );

  const deleteApiKeyMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscription.id}/apps/{appId}/keys/{keyId}`,
    { method: "DELETE" },
  );

  const deleteAppMutation = useSignedMutation(
    `/v1/developer/subscriptions/${subscription.id}/apps/{appId}`,
    { method: "DELETE" },
  );

  return (
    <div className="self-start border rounded shadow w-full">
      <div className="bg-border/20 p-6 border-b flex justify-between items-center">
        <div>
          <div id="minutecast" className="font-semibold text-lg">
            {subscription.label}
          </div>
          <p className="text-muted-foreground text-sm">
            Showing consumption for November
          </p>
        </div>
        <div className="flex gap-2">
          {subscription.manageable !== false && (
            <Button variant="outline" className="flex gap-2" asChild>
              <Link to={`/subscriptions/${subscription.id}`}>
                <EditIcon size={18} />
                Manage
              </Link>
            </Button>
          )}
          <AddAppDialog subscriptionId={subscription.id} />
        </div>
      </div>
      <div className="border-b p-6 flex flex-col gap-1.5 not-prose">
        <div className="flex justify-between">
          <span className="font-semibold">Calls</span>
          <span className="text-muted-foreground">
            <span
              className={cn(
                subscription.usage.used / subscription.usage.budget > 1 &&
                  "text-red-600",
              )}
            >
              {new Intl.NumberFormat().format(subscription.usage.used)}
            </span>{" "}
            of {new Intl.NumberFormat().format(subscription.usage.budget)}
          </span>
        </div>
        <Progress
          value={(subscription.usage.used / subscription.usage.budget) * 100}
          size="lg"
          variant={
            (subscription.usage.used / subscription.usage.budget) * 100 > 100
              ? "warning"
              : "default"
          }
        />
        <p className="text-muted-foreground text-sm">
          You will be charged for usage beyond your limits.{" "}
          <Link to="/pricing" className="hover:underline text-primary">
            View pricing.
          </Link>
        </p>
      </div>
      <div className=" divide-y divide-neutral-300">
        {subscription.apps.map((app) => (
          <div className="p-6">
            <ApiKeyGroup
              title={app.label}
              service={{
                deleteKey: (id) =>
                  deleteApiKeyMutation.mutateAsync({
                    params: { keyId: id, appId: app.id },
                  }),
                deleteGroup: (id) =>
                  deleteAppMutation.mutateAsync({
                    params: { appId: app.id },
                  }),
                rollKey: (id) => {
                  return rollApiKeyMutation.mutateAsync({
                    params: { keyId: id, appId: app.id },
                  });
                },
              }}
              id={app.id}
              keys={app.keys}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const Page = () => {
  const subscriptionsQuery = useSignedQuery<Subscription[]>(SUBSCRIPTIONS_PATH);

  return (
    <section className="flex flex-col py-12 gap-10 max-w-screen-xl mx-auto not-prose">
      <Head>
        <title>Pricing</title>
      </Head>
      <div className="flex items-center justify-between">
        <h2 className="font-semibold text-3xl">My Subscriptions</h2>
        {/*<UpdateBillingInformation />*/}
      </div>
      {subscriptionsQuery.isLoading && (
        <>
          <SubscriptionSkeleton />
          <SubscriptionSkeleton />
        </>
      )}
      {subscriptionsQuery.data?.map((subscription) => (
        <SubscriptionCard key={subscription.id} subscription={subscription} />
      ))}
    </section>
  );
};

export const SubscriptionsPage = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Page />
    </QueryClientProvider>
  );
};
